import { MAX_FUTURE_DATE, MAX_PAST_DATE } from '@utils/dateUtils'
import * as yup from 'yup'

export const ContactLensValidationSchema = yup.object({
  issue: yup
    .date()
    .nullable()
    .when('$issueIsRequired', {
      is: true,
      then: schema => schema.optional().nullable().default(undefined),
      otherwise: schema => schema.required('Required').min(MAX_PAST_DATE).max(MAX_FUTURE_DATE),
    }),

  doctor: yup
    .string()
    .nullable()
    .when('$doctorIsRequired', {
      is: true,
      then: schema => schema.optional().nullable().default(undefined),
      otherwise: schema => schema.required('Required'),
    }),
  isAcknowledged: yup
    .boolean()
    .nullable()
    .when('$requiresAcknowledgement', {
      is: true,
      then: schema => schema.required('Required').oneOf([true]),
      otherwise: schema => schema.optional().nullable().default(false),
    }),
})

export type ContactLensFormFields = yup.InferType<typeof ContactLensValidationSchema>
