import React, { Fragment, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  ContactLensAttributesList,
  ContactLensAttributesListItem,
  ContactLensDetailsContent,
  ContactLensDetailsTitle,
  ContactLensDetailsWrapper,
  ContactLensAttributesListItemTitle,
} from './OrderRecapClItemLensDetails.style'
import { OrderItemContactLensData } from '../../types/cart'
import { size, uniq, map, toNumber } from 'lodash-es'
import { useTranslation } from 'next-i18next'
import styles from './styles/OrderRecapItem.module.scss'
import useBreakpoints from '@hooks/useBreakpoints'
import { formatClDataForMobileView } from '@components/PrescriptionLenses/RxUtils'
import { usePageType } from '@foundation/hooks/usePageType'
import { CartRecapActionItem } from '@views/Cart/components/CartRecap/CartRecap.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { PrescriptionFormData } from '@typesApp/prescription'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import { format, isValid } from 'date-fns'
import { OrderItem } from '@typesApp/order'
import { isNonPrescriptionColoredLens } from './helpers/isNonPrescriptionColoredLens'

const formatPrescriptionTableHeadings = (label: string, abbreviate: boolean): string => {
  return abbreviate ? label.substring(0, 3) : label
}

export interface OrderRecapClItemLensDetailsProps {
  orderItemClData: OrderItemContactLensData
  prescriptionDetails?: PrescriptionFormData
  showQuantityPerEye?: boolean
  alwaysShowPrescription?: boolean
  onEdit?: () => void
}

export const OrderRecapClItemLensDetails: React.FC<OrderRecapClItemLensDetailsProps> = ({
  orderItemClData,
  showQuantityPerEye,
  alwaysShowPrescription,
  onEdit,
  prescriptionDetails,
}) => {
  const theme = useTheme()
  const { t: translate } = useTranslation()
  const [showPrescription, setShowPrescription] = useState<boolean>()
  const rxTaxExemption = useRxTaxExemption()
  const { isMobile, isViewportWidthAbove1240, isViewportWidthAbove1440, isViewportWidthAbove1950 } = useBreakpoints()

  const labelsArray = ['eye']
  const getLabels = (): string[] => {
    if (orderItemClData) {
      for (const eye in orderItemClData) {
        if (orderItemClData.hasOwnProperty(eye)) {
          const data = orderItemClData[eye].data
          if (data) {
            Object.keys(data).forEach(key => {
              labelsArray.push(key)
            })
          }
          if (showQuantityPerEye && orderItemClData[eye].quantity) {
            labelsArray.push('Boxes')
          }
        }
      }
    }
    return uniq(labelsArray)
  }

  const formatClDataForMobile = formatClDataForMobileView(orderItemClData, showQuantityPerEye)
  const { pageType } = usePageType()
  const isCheckoutPage = pageType === 'checkout'
  const abbreviateText =
    isCheckoutPage ||
    (getLabels().length > 6 && !isViewportWidthAbove1950) ||
    (getLabels().length > 3 && !isViewportWidthAbove1240)
  const shrinkText = isCheckoutPage
    ? getLabels().length > 6 && !isViewportWidthAbove1950
    : getLabels().length > 6 && !isViewportWidthAbove1440

  const issueDate = new Date(prescriptionDetails?.issue + '')
  const renderPrescriptionDetails = !isNonPrescriptionColoredLens({ orderItemClData }) &&
    rxTaxExemption.isEnabled &&
    prescriptionDetails && (
      <>
        <ContactLensAttributesList className={styles.prescriptionDetailsTable}>
          <ContactLensAttributesListItem className={styles.prescriptionDetailsColumn}>
            <ContactLensAttributesListItemTitle isCompact={shrinkText}>
              {translate('OrderRecap.IssueDate')}
            </ContactLensAttributesListItemTitle>
          </ContactLensAttributesListItem>
          {rxTaxExemption.showFullForm && (
            <ContactLensAttributesListItem className={styles.prescriptionDetailsColumn}>
              <ContactLensAttributesListItemTitle isCompact={shrinkText}>
                {translate('OrderRecap.DoctorsName')}
              </ContactLensAttributesListItemTitle>
            </ContactLensAttributesListItem>
          )}
        </ContactLensAttributesList>

        <ContactLensAttributesList className={styles.prescriptionDetailsTable}>
          <ContactLensAttributesListItem className={styles.prescriptionDetailsColumn}>
            <span>{isValid(issueDate) ? format(issueDate, 'MM/yyyy') : '-'}</span>
          </ContactLensAttributesListItem>
          {rxTaxExemption.showFullForm && (
            <ContactLensAttributesListItem className={styles.prescriptionDetailsColumn}>
              <span>{prescriptionDetails.doctor ?? '-'}</span>
            </ContactLensAttributesListItem>
          )}
        </ContactLensAttributesList>
      </>
    )

  return (
    <ContactLensDetailsWrapper className={styles.dropdown} expanded={showPrescription || alwaysShowPrescription}>
      <div>
        {!alwaysShowPrescription && (
          <ContactLensDetailsTitle
            isCheckoutPage={isCheckoutPage}
            style={{ justifyContent: 'unset' }}
            expandIcon={
              <SVGIcon
                library="arrow"
                name="arrow-down"
                color={theme.palette.text.dark.primary}
                style={{ marginLeft: theme.spacing(2) }}
              />
            }
            onClick={() => setShowPrescription(!showPrescription)}
            aria-controls="contact-lenses-details-content"
            id="contact-lenses-details-header"
          >
            {translate('CartRecap.Labels.SeePrescriptionDetails')}
          </ContactLensDetailsTitle>
        )}
        {!!onEdit && (
          <CartRecapActionItem onClick={() => onEdit()} data-element-id="X_X_Prods_Edit">
            <span>
              <SVGIcon library="account" name="edit" size={18} />
            </span>
            <span> {translate('CartRecap.Actions.Edit')}</span>
          </CartRecapActionItem>
        )}
      </div>
      <ContactLensDetailsContent>
        {(isMobile && (
          <>
            {!!formatClDataForMobile &&
              Object.keys(formatClDataForMobile).map((key, i) => {
                return (
                  <Fragment key={`${key}_${i}`}>
                    <ContactLensAttributesList>
                      <ContactLensAttributesListItemTitle>
                        {translate(`CartRecap.Labels.${key}`)}
                      </ContactLensAttributesListItemTitle>
                      {!!formatClDataForMobile[key].left ? (
                        <ContactLensAttributesListItem>
                          <span> {formatClDataForMobile[key].left}</span>
                        </ContactLensAttributesListItem>
                      ) : null}
                      {!!formatClDataForMobile[key].right ? (
                        <ContactLensAttributesListItem>
                          <span> {formatClDataForMobile[key].right}</span>
                        </ContactLensAttributesListItem>
                      ) : null}
                    </ContactLensAttributesList>
                  </Fragment>
                )
              })}

            {renderPrescriptionDetails}
          </>
        )) || (
          <>
            <ContactLensAttributesList>
              {getLabels().map((label, i) => {
                const showLabel = !(!showQuantityPerEye && label === 'Boxes')
                return (
                  showLabel && (
                    <ContactLensAttributesListItem key={`${label}_${i}`}>
                      <ContactLensAttributesListItemTitle isCompact={shrinkText}>
                        {formatPrescriptionTableHeadings(translate(`CartRecap.Labels.${label}`), abbreviateText)}
                      </ContactLensAttributesListItemTitle>
                    </ContactLensAttributesListItem>
                  )
                )
              })}
            </ContactLensAttributesList>
            {!!orderItemClData &&
              Object.keys(orderItemClData).map((eye, i) => {
                return (
                  <Fragment key={`${eye}_${i}`}>
                    <ContactLensAttributesList>
                      {!!orderItemClData[eye]?.data && size(orderItemClData[eye].data) > 0 ? (
                        <ContactLensAttributesListItem isCompact={shrinkText}>
                          <span> {translate(`CartRecap.Labels.${eye}Eye`)}</span>
                        </ContactLensAttributesListItem>
                      ) : null}

                      {!!orderItemClData[eye]?.data &&
                        map(orderItemClData[eye].data, (value, key) => {
                          return (
                            <ContactLensAttributesListItem key={`${key}_${value}`} isCompact={shrinkText}>
                              <span>{typeof value === 'string' ? value : value?.toString() ?? null}</span>
                            </ContactLensAttributesListItem>
                          )
                        })}
                      {showQuantityPerEye && orderItemClData[eye]?.quantity && (
                        <ContactLensAttributesListItem isCompact={shrinkText}>
                          <span>{toNumber(orderItemClData[eye].quantity)}</span>
                        </ContactLensAttributesListItem>
                      )}
                    </ContactLensAttributesList>
                  </Fragment>
                )
              })}
            {renderPrescriptionDetails}
          </>
        )}
      </ContactLensDetailsContent>
    </ContactLensDetailsWrapper>
  )
}
