import { StyledTextField as TextField } from '@views/Prescriptions/Prescriptions.style'
import { DatePicker } from '@components/UI'
import styles from './styles.module.scss'
import { clsx } from 'clsx'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { parse, isValid as dateIsValid, format } from 'date-fns'

type IssueDateInputProps = {
  name: string
  message?: string
  required?: boolean
  label: string
  value?: Date
  onChange?: (date: Date | null) => void
  hasError?: boolean
  isValid?: boolean
  isPristine?: boolean
  errorText?: string
  disabled?: boolean
}

export function IssueDateInput(props: IssueDateInputProps) {
  const { message, required, label, value, onChange, hasError, isValid, isPristine, disabled } = props
  const { t } = useTranslation(undefined, { keyPrefix: 'PrescriptionDetailsForm' })

  const handleDateChange = (date: Date | null) => {
    onChange?.(date)
  }

  return (
    <div className={styles.inputField}>
      <DatePicker
        views={['year', 'month']}
        value={value ?? null}
        disabled={disabled}
        mask="__/____"
        inputFormat="MM/yyyy"
        className={clsx({
          [styles.disabledField]: disabled,
        })}
        onMonthChange={handleDateChange}
        onChange={handleDateChange}
        onAccept={handleDateChange}
        onYearChange={handleDateChange}
        disableOpenPicker
        shouldDisableDate={() => true}
        renderInput={params => (
          <TextField
            {...params}
            disabled={disabled}
            required={required}
            error={hasError}
            helperText={message}
            isvalid={isValid}
            ispristine={isPristine}
            showvalidationstatus
            onInput={e => {
              handleDateChange(parse(e.target['value'], 'MM/yyyy', new Date()))
            }}
            placeholder="MM/YYYY"
            inputProps={{
              ...params.inputProps,
              placeholder: 'MM/YYYY',
            }}
          />
        )}
        label={label}
      />
    </div>
  )
}
