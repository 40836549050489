import { getOrderItemClData } from '@hooks/useContactLensCartItemData/useContactLensCartItemData'
import { PrescriptionPayload, useCreatePrescriptionDetailsMutation } from '@features/order/query'
import { useSelector } from 'react-redux'
import { cartSelector } from '@features/order/selector'
import { RegisterFormParams } from '@views/Cart/contexts/CartContextProvider/CartContext'
import { isContactLensesProduct } from '@utils/product'
import { OrderItem } from '@typesApp/order'

export function useCreateContactLensPrescription() {
  const cart = useSelector(cartSelector)

  const [createPrescriptionDetailsMutation] = useCreatePrescriptionDetailsMutation()

  const createPrescriptionDetails = async (data: RegisterFormParams[]) => {
    const promises = data.map(createPrescriptionDetail)
    await Promise.all(promises)
  }

  const createPrescriptionDetail = async (data: RegisterFormParams) => {
    const payload = createPayload(data)
    if (!payload) {
      return Promise.resolve()
    }

    return createPrescriptionDetailsMutation(payload).unwrap
  }

  const createPayload = (data: RegisterFormParams): PrescriptionPayload | undefined => {
    if (!isContactLensesProduct(data.orderItem)) {
      return
    }

    const contactLensData = getOrderItemClData(data.orderItem)

    if (!contactLensData) {
      return
    }

    let orderItemId = getOrderItemIdString(data.orderItem)

    const form = data.getValues()
    return {
      orderId: cart.orderId,
      orderItemId: orderItemId,
      prescription: {
        doctor: form.doctor ?? '',
        productType: 'CL',
        nickName: '',
        firstName: '',
        lastName: '',
        telephone: '',
        dateOfBirth: '',
        prescriptionState: '',
        issue: form.issue ?? undefined,
        rightBaseCurve: contactLensData?.right?.data?.['x_baseCurve'],
        rightDiameter: contactLensData?.right?.data?.['x_diameter'],
        rightPrismPower: contactLensData?.right?.data?.['x_spherePower'],
        leftBaseCurve: contactLensData?.left?.data?.['x_baseCurve'],
        leftPrismPower: contactLensData?.left?.data?.['x_spherePower'],
        leftDiameter: contactLensData?.left?.data?.['x_diameter'],
      },
    }
  }

  const getOrderItemIdString = (orderItem: OrderItem) => {
    const orderItemClData = getOrderItemClData(orderItem)
    if (!orderItemClData) {
      return ''
    }

    const leftOrderItemId = orderItemClData.left?.orderItemId ?? ''
    const rightOrderItemId = orderItemClData.right?.orderItemId ?? ''

    if (leftOrderItemId && rightOrderItemId) {
      return `${leftOrderItemId},${rightOrderItemId}`
    }

    return leftOrderItemId || rightOrderItemId
  }

  return [createPrescriptionDetails]
}
