import { StyledTextField as TextField } from '@views/Prescriptions/Prescriptions.style'
import React from 'react'
import styles from './styles.module.scss'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import { clsx } from 'clsx'
import { useTranslation } from 'next-i18next'

type IssueDoctorInputProps = {
  name: string
  message?: string
  required?: boolean
  label: string
  value?: string
  onChange?: (value: string) => void
  hasError?: boolean
  isValid?: boolean
  isPristine?: boolean
  errorText?: string
  disabled?: boolean
}

export function DoctorNameInput(props: IssueDoctorInputProps) {
  const { value, onChange, hasError, isValid, isPristine, label, message, required, disabled } = props
  const { t } = useTranslation(undefined, { keyPrefix: 'PrescriptionDetailsForm' })
  const { requireBasicInfo } = useRxTaxExemption()

  if (requireBasicInfo) {
    return null
  }

  return (
    <div className={styles.inputField}>
      <TextField
        value={value ?? null}
        label={label}
        className={clsx({
          [styles.disabledField]: disabled,
        })}
        onChange={v => {
          if (onChange) {
            onChange(v.target.value)
          }
        }}
        disabled={disabled}
        required={required}
        error={hasError}
        helperText={message}
        isvalid={isValid}
        ispristine={isPristine}
        showvalidationstatus
        inputProps={{ maxLength: 200 }}
      />
    </div>
  )
}
